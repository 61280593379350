<template>
  <v-dialog
    v-if="active && overlay"
    v-model="active"
    hide-overlay
    persistent
    width="300"
  >
    <v-card color="primary" dark>
      <v-card-text>
        {{ text || 'Загрузка...' }}
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
  <v-main v-else-if="active">
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <!-- <v-flex v-if="text" tag="h1" class="text-center headline pb-2">{{text}}</v-flex> -->
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script lang="ts" src="./LoadingComponent.ts">
</script>


<style  scoped>
.loading-content {
  width: 100%;
  height: 100%;
}
</style>
