@import '@veles/app/styles/variables.scss';
@mixin notransition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.veles-background-full {
	@include notransition;
	background-image: url('../images/background/bottom-left.svg'),
		url('../images/background/bottom-right.svg'),
		url('../images/background/top-right.svg');
	background-position: bottom left, bottom right, top right;
}

.veles-background-bottom {
	@include notransition;
	background-image: url('../images/background/bottom-left.svg'),
		url('../images/background/bottom-right.svg');
	background-position: bottom left, bottom right;
}

@media screen and (max-width: 1070px) {
	.veles-background-full {
		@include notransition;
		background-image: 			url('../images/background/bottom-right.svg'),
			url('../images/background/top-right.svg');
		background-position: bottom right, top right;
	}

	.veles-background-bottom {
		@include notransition;
		background-image: url('../images/background/bottom-right.svg');
		background-position: bottom right;
	}
}
